import { Affix, Flex } from 'antd';
import React from 'react';
import { OPEN_INCIDENT_STATUS, SEARCH_PARAMETERS_API_IDS, STATUS_API_IDS } from '~/helpers/order/catalogue';
import { useOrdersCount } from '~/hooks/useOrders';

import TabsGroup from '../TabsGroup/TabsGroup';
import './StickyTitleWithStatus.scss';

export default function StickyTitleWithStatus({ activeMerchantId, panelRef, params, changeParams }) {
  const { pending, data } = useOrdersCount(activeMerchantId);

  if (pending) {
    return null;
  }

  if (!data) {
    return null;
  }

  const { ordersOpenIncidentsCount, ordersAuthorizedCount, ordersConfirmedCount } = data;

  const { STATUS, INCIDENT_STATUS } = SEARCH_PARAMETERS_API_IDS;

  const tabItems = [
    {
      label: 'Toutes',
      active: !params[STATUS]?.length && !params[INCIDENT_STATUS]?.length,
      param: {
        key: STATUS,
        value: '',
      },
    },
    {
      label: 'À confirmer',
      active: params[STATUS] === STATUS_API_IDS.AUTHORIZED,
      param: {
        key: STATUS,
        value: STATUS_API_IDS.AUTHORIZED,
      },
      count: {
        value: ordersAuthorizedCount,
        color: 'success',
      },
    },
    {
      label: 'À traiter',
      active: params[STATUS] === STATUS_API_IDS.CONFIRMED,
      param: {
        key: STATUS,
        value: STATUS_API_IDS.CONFIRMED,
      },
      count: {
        value: ordersConfirmedCount,
        color: 'warning',
      },
    },
    {
      label: 'Incidents ouverts',
      active: params[INCIDENT_STATUS] === OPEN_INCIDENT_STATUS,
      param: {
        key: INCIDENT_STATUS,
        value: OPEN_INCIDENT_STATUS,
      },
      count: {
        value: ordersOpenIncidentsCount,
        color: 'error',
      },
    },
    {
      label: 'Traitées',
      active: params[STATUS] === STATUS_API_IDS.PROCESSED,
      param: {
        key: STATUS,
        value: STATUS_API_IDS.PROCESSED,
      },
    },
  ].map((item) => ({
    ...item,
    onClick: () => {
      const newParams = { ...params };
      delete newParams[STATUS];
      delete newParams[INCIDENT_STATUS];
      newParams[item.param.key] = item.param.value;
      changeParams(newParams);
    },
  }));

  return (
    <Affix target={() => panelRef.current} offsetTop={0}>
      <Flex vertical gap={8} className='orders-catalogue-sticky-title-wrapper'>
        <h1 className='h1'>Gérer les commandes</h1>
        <TabsGroup items={tabItems} />
      </Flex>
    </Affix>
  );
}
