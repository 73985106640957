import { DownOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import './ExpandableContent.scss';

/**
 * ExpandableContent Component
 *
 * This component is used to create a section of content that can be expanded and collapsed.
 *
 * Props:
 * - title: The title of the section. This is displayed as a button that the user can click to expand or collapse the content.
 * - children: The content to be displayed when the section is expanded.
 *
 * Note: This component uses the `height` property for the transition effect. However, this requires the content's height to be known beforehand.
 * If the content's height is dynamic (i.e., it can change based on user interaction or other factors), this component might not work as expected.
 * In such cases, consider using a different method for the transition effect, such as transforming the `scaleY` property.
 */
export default function ExpandableContent({ title, titleClassName = null, withArrow = false, children }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [height, setHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    setHeight(contentRef.current ? contentRef.current.offsetHeight : 0);
  }, []);

  const props = useSpring({
    height: isExpanded ? height : 0,
    config: { tension: 170, friction: 26 },
    onRest: () => {},
    onStart: () => {},
  });

  return (
    <div className='expandable-wrapper'>
      {withArrow ? (
        <Flex className='expandable-link' onClick={() => setIsExpanded(!isExpanded)} justify='space-between' align='center'>
          <p className={titleClassName ? titleClassName : 'sub1'}>{title}</p>
          <Button type='text' size='large' className={`btn-icon${isExpanded ? ' expanded' : ''}`} icon={<DownOutlined />} />
        </Flex>
      ) : (
        <Button className='expandable-link' type='link' onClick={() => setIsExpanded(!isExpanded)}>
          <span className={titleClassName}>{title}</span>
        </Button>
      )}
      <animated.div
        className='expandable-content'
        style={{
          overflow: 'hidden',
          height: props.height.to((h) => `${h}px`),
        }}
      >
        <div ref={contentRef}>{children}</div>
      </animated.div>
    </div>
  );
}
