import Icon from '@ant-design/icons';
import { Flex, Input, Tooltip } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import QuestionMark from '~/assets/svg/icon-question-mark.svg?react';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import ExpandableContent from '~/components/shared/ExpandableContent/ExpandableContent';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { HANDLE_CHANGE } from '~/store/reducers/products';

import PrintSticker from './PrintSticker/PrintSticker';

function Storage({ dispatch, component, editionMode, skuValue, locationValue }) {
  const { sku, location } = component;

  const propagateChange = (key, value) => {
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: key,
        value: value,
      },
    });
  };

  return (
    <>
      <div className='form-item-header'>
        <p className='h2'>Stockage</p>
      </div>

      <Flex vertical gap={8}>
        <Flex gap={8} align='center'>
          <p className='body2'>
            {sku.label}
            {sku.validation_rules.required && <Asterisk />}
          </p>
          {sku.helptext && (
            <Tooltip title={sku.helptext} placement='right'>
              <Icon className='text-primary icon' component={QuestionMark} />
            </Tooltip>
          )}
        </Flex>
        <Input
          id={sku.key}
          defaultValue={skuValue}
          onBlur={(e) => propagateChange(sku.key, e.target.value)}
          onPressEnter={(e) => propagateChange(location.key, e.target.value)}
          maxLength={sku.validation_rules.max_length}
        />
        <p className='subtext'>Limite de caractères : {sku.validation_rules.max_length}</p>
      </Flex>

      <ExpandableContent title='Qu’est-ce que le numéro SKU ?'>
        <p className='body1'>
          Code unique attribué automatiquement pour faciliter l'identification, le suivi et la gestion des stocks.
          <br />
          Il se compose de votre Identifiant marchand, de la date et du nombre d'annonces créées ce jour.
          <br />
          Modifiable avec un minimum de 5 caractères.
        </p>
      </ExpandableContent>

      <Flex vertical gap={8}>
        <Flex gap={8} align='center'>
          <p className='body2'>
            {location.label}
            {location.validation_rules.required && <Asterisk />}
          </p>
        </Flex>
        <Input
          id={location.key}
          placeholder={location.helptext}
          defaultValue={locationValue}
          onBlur={(e) => propagateChange(location.key, e.target.value)}
          onPressEnter={(e) => propagateChange(location.key, e.target.value)}
        />
      </Flex>

      <PrintSticker editionMode={editionMode} />
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const skuValue = form_fields[AD_DETAILS_API_IDS.TECHNICAL_INFO.SKU];
  const locationValue = form_fields[AD_DETAILS_API_IDS.TECHNICAL_INFO.LOCATION];
  return {
    skuValue,
    locationValue,
  };
})(Storage);
