import { Button, Descriptions, Flex, Modal } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AD_DETAILS_API_IDS, CATEGORY_API_IDS } from '~/helpers/adDetails';

const mapStateDescriptionToCategory = {
  [CATEGORY_API_IDS.HIGH_TECH_ELECTRO.ID]: [
    {
      key: 'Comme Neuf',
      label: 'Comme Neuf',
      children: <p className='caption1'>Appareil non utilisé, testé, fonctionnel, nettoyé et sans rayures.</p>,
    },
    {
      key: 'Très bon état',
      label: 'Très bon état',
      children: (
        <p className='caption1'>
          Appareil pas ou peu utilisé, testé, fonctionnel, et nettoyé. Imperfections légères visibles à moins de 20 cm à mentionner dans l'annonce.
        </p>
      ),
    },
    {
      key: 'Bon état',
      label: 'Bon état',
      children: (
        <p className='caption1'>
          Appareil testé, fonctionnel, et nettoyé, présente des imperfections ou signes d'usure à mentionner dans l'annonce. Petits défauts visibles à moins de
          20 cm.
        </p>
      ),
    },
    {
      key: 'Etat correct',
      label: 'Etat correct',
      children: (
        <p className='caption1'>
          'Appareil testé, et nettoyé. Présente des signes d’usure détaillés en description et en photos. On voit que le bien a vécu mais est en parfait état de
          fonctionnement.'
        </p>
      ),
    },
  ],
  [CATEGORY_API_IDS.LIBRAIRIE.ID]: [
    {
      key: 'Comme neuf',
      label: 'Comme neuf',
      children: <p className='caption1'>Excellent état, ou encore sous blister. Les pages ne présentent aucunes salissures, plis ou annotations.</p>,
    },
    {
      key: 'Très bon état',
      label: 'Très bon état',
      children: <p className='caption1'>Légères imperfections, aucune annotation.</p>,
    },
    {
      key: 'Bon état',
      label: 'Bon état',
      children: <p className='caption1'>Signes d’usure sur la couverture et/ou les pages, aucune annotation.</p>,
    },
    {
      key: 'Etat correct',
      label: 'Etat correct',
      children: <p className='caption1'>Signes d’usure sur la couverture et/ou les pages, présente des annotations (crayon, stylo, marqueur).</p>,
    },
  ],
  [CATEGORY_API_IDS.LOISIRS.ID]: [
    {
      key: 'Comme neuf',
      label: 'Comme neuf',
      children: <p className='caption1'>Article en excellent état, avec ou sans étiquette.</p>,
    },
    {
      key: 'Très bon état',
      label: 'Très bon état',
      children: <p className='caption1'>Article porté/utilisé quelques fois, présente de légères imperfections à détailler dans l’annonce.</p>,
    },
    {
      key: 'Bon état',
      label: 'Bon état',
      children: <p className='caption1'>Article porté/utilisé plusieurs fois, présente des imperfections ou signes d'usure à mentionner dans l'annonce.</p>,
    },
    {
      key: 'Etat correct',
      label: 'Etat correct',
      children: (
        <p className='caption1'>
          Article porté/utilisé plusieurs fois, présente des signes usure détaillés en description et en photos. On voit que l’article a vécu, sans que cela en
          empêche l'usage.,
        </p>
      ),
    },
  ],
  [CATEGORY_API_IDS.MAISON.ID]: [
    {
      key: 'Neuf',
      label: 'Neuf',
      children: <p className='caption1'>Uniquement pour un article créé ou transformé dans vos ateliers (artisanat, upcycling)</p>,
    },
    {
      key: 'Comme neuf',
      label: 'Comme neuf',
      children: <p className='caption1'>Article en excellent état, avec ou sans étiquette.</p>,
    },
    {
      key: 'Très bon état',
      label: 'Très bon état',
      children: <p className='caption1'>Article porté/utilisé quelques fois, présente de légères imperfections à mentionner dans l’annonce.</p>,
    },
    {
      key: 'Bon état',
      label: 'Bon état',
      children: <p className='caption1'>Article porté/utilisé, présente des imperfections ou signes d'usure à mentionner dans l'annonce.</p>,
    },
    {
      key: 'Etat correct',
      label: 'Etat correct',
      children: (
        <p className='caption1'>
          Article porté/utilisé, présente des signes d’usure détaillés en description et en photos. On voit que l’article a vécu, sans que cela en empêche
          l'usage.
        </p>
      ),
    },
  ],
  [CATEGORY_API_IDS.MAISON.ELECTROMENAGER.ID]: [
    {
      key: 'Comme neuf',
      label: 'Comme neuf',
      children: <p className='caption1'>Appareil non utilisé, testé, fonctionnel, nettoyé et sans rayures.</p>,
    },
    {
      key: 'Très bon état',
      label: 'Très bon état',
      children: (
        <p className='caption1'>
          Appareil pas ou peu utilisé, testé, fonctionnel, et nettoyé. Imperfections légères visibles à moins de 20 cm à mentionner dans l'annonce.,
        </p>
      ),
    },
    {
      key: 'Bon état',
      label: 'Bon état',
      children: (
        <p className='caption1'>
          Appareil testé, fonctionnel, et nettoyé, présente des imperfections ou signes d'usure à mentionner dans l'annonce. Petits défauts visibles à moins de
          20 cm.
        </p>
      ),
    },
    {
      key: 'Etat correct',
      label: 'Etat correct',
      children: (
        <p className='caption1'>
          Appareil testé, et nettoyé. Présente des signes d’usure détaillés en description et en photos. On voit que le bien a vécu mais est en parfait état de
          fonctionnement.
        </p>
      ),
    },
  ],
  [CATEGORY_API_IDS.MODE.ID]: [
    {
      key: 'Neuf',
      label: 'Neuf',
      children: <p className='caption1'>Uniquement pour un article créé ou transformé dans vos ateliers (artisanat, upcycling)</p>,
    },
    {
      key: 'Comme neuf',
      label: 'Comme neuf',
      children: <p className='caption1'>Article en excellent état, avec ou sans étiquette.</p>,
    },
    {
      key: 'Très bon état',
      label: 'Très bon état',
      children: <p className='caption1'>Article porté/utilisé quelques fois, présente de légères imperfections à mentionner dans l'annonce.</p>,
    },
    {
      key: 'Bon état',
      label: 'Bon état',
      children: <p className='caption1'>Article porté/utilisé, présente des imperfections ou signes d'usure à mentionner dans l'annonce.</p>,
    },
    {
      key: 'Etat correct',
      label: 'Etat correct',
      children: (
        <p className='caption1'>
          Article porté/utilisé, présente des signes d’usure détaillés en description et en photos. On voit que l’article a vécu, sans que cela en empêche
          l'usage.
        </p>
      ),
    },
  ],
};

function ModalStateDescription({ categories }) {
  const [showModal, setShowModal] = useState(false);
  const parentCategory = categories[0].id;

  let stateDescription;
  if (parentCategory === CATEGORY_API_IDS.MAISON.ID && categories[1].id === CATEGORY_API_IDS.MAISON.ELECTROMENAGER.ID) {
    stateDescription = mapStateDescriptionToCategory[CATEGORY_API_IDS.MAISON.ELECTROMENAGER.ID];
  } else {
    stateDescription = mapStateDescriptionToCategory[parentCategory];
  }

  return (
    <>
      <Button type='link' onClick={() => setShowModal(true)}>
        Consulter le guide des états
      </Button>
      <Modal open={showModal} onCancel={() => setShowModal(false)} footer={null}>
        <Flex vertical gap={16}>
          <p className='h4'>Guide des états</p>
          <Descriptions labelStyle={{ whiteSpace: 'nowrap' }} bordered column={1} size='small' items={stateDescription} />
        </Flex>
      </Modal>
    </>
  );
}

export default connect((state) => {
  return {
    categories: state.products.form_fields[AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE],
  };
})(ModalStateDescription);
