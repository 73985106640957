import { Divider, Flex, Spin } from 'antd';
import React from 'react';
import { useConversation } from '~/hooks/useConversations';

import ConversationOrderSummary from './ConversationOrderSummary/ConversationOrderSummary';
import Messages from './Messages/Messages';
import SendMessage from './SendMessage/SendMessage';

function ConversationDetails({ conversationId }) {
  const { data: conversation, isLoading } = useConversation(conversationId);

  if (isLoading) {
    return (
      <Flex justify='center' align='center' className='h-full grow'>
        <Spin size='large' />
      </Flex>
    );
  }

  return (
    <Flex className='grow' vertical gap={16}>
      <ConversationOrderSummary conversation={conversation} />
      <Divider />
      <Messages messages={conversation.messages} />
      <SendMessage conversationId={conversation.id} isClosed={conversation.status === 'closed'} />
    </Flex>
  );
}

export default ConversationDetails;
