import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { App, Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import DefaultImageIcon from '~/assets/svg/icon-default-image.svg?react';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { DELETE_IMAGE, REORDER_IMAGE } from '~/store/reducers/products';
import { replaceImage, uploadImage } from '~/store/reducers/products/actions';

import ImageEditor from './ImageEditor/ImageEditor';
import PhotoItem from './PhotoItem/PhotoItem';
import './PhotosGrid.scss';

function ImportPhotoDropzone({ onDrop, onDropRejected }) {
  return (
    <Dropzone className='photo-dropzone photo-item-block' accept='image/*' onDrop={onDrop} onDropRejected={onDropRejected}>
      <Button type='link'>Sélectionnez</Button>
      <p className='body-1 text-center text-grey-medium'>ou déposez vos photos </p>
    </Dropzone>
  );
}

export function compressImage(file, maxSize) {
  return new Promise((resolve, reject) => {
    let inputImage = new Image();

    inputImage.src = URL.createObjectURL(file);
    inputImage.onload = () => {
      let c = document.createElement('canvas'),
        ctx = c.getContext('2d'),
        compressionValue = 1,
        compressionStep = 0.1;

      URL.revokeObjectURL(inputImage.src);

      c.width = inputImage.width;
      c.height = inputImage.height;
      ctx.drawImage(inputImage, 0, 0);

      function compressionLoop(canvas, currentCompressionValue) {
        let nextCompressionValue = currentCompressionValue - compressionStep;
        return function (blob) {
          if (blob !== null && blob.size >= maxSize && nextCompressionValue > 0) {
            canvas.toBlob(compressionLoop(canvas, nextCompressionValue), 'image/jpeg', nextCompressionValue);
          } else if (nextCompressionValue <= 0 || blob === null) {
            reject();
          } else {
            // Replace the old file extension to jpeg, the correct extension since we swap the image type
            const newFileNameArray = file.name.split('.');
            newFileNameArray.pop();
            newFileNameArray.push('jpeg');
            const newFileName = newFileNameArray.join('.');

            resolve(
              new File([blob], newFileName, {
                lastModified: new Date().getTime(),
                type: blob.type,
              })
            );
          }
        };
      }

      c.toBlob(compressionLoop(c, compressionValue), 'image/jpeg', compressionValue);
    };
  });
}

const maxOriginalSize = 12000000;

const fileTypeError = 'Fichier non autorisé (images uniquement)';
const maxSizeError = 'Poids maximum : 12mo';

function formatErrors(errors) {
  return {
    className: 'notification-dialogue-error',
    message: `Erreur lors de l'import de${errors.length > 1 ? 's images' : " l'image"}`,
    description: (
      <ul>
        {errors.map((error) => (
          <li key={error.name}>
            {error.name} : {error.message}
          </li>
        ))}
      </ul>
    ),
  };
}

function PhotosGrid({ component, validationRules, dispatch, images }) {
  const { key, label, helptext } = component;
  const { max_file_weight } = validationRules[key];

  if (!images) {
    return <></>;
  }

  const { notification } = App.useApp();
  const [replacingImage, setReplacingImage] = useState(false);
  const [items, setItems] = useState(images);
  const [errors, setErrors] = useState([]);
  const [showEdition, setShowEdition] = useState(null);

  useEffect(() => {
    setItems(images);
  }, [images]);

  useEffect(() => {
    if (errors.length) {
      notification.open(formatErrors(errors));
      setErrors([]);
    }
  }, [errors]);

  const showNextImageEdition = (currentIndex) => {
    const nextIndex = currentIndex === items.length - 1 ? 0 : currentIndex + 1;
    const imageToGo = items[nextIndex];
    if (imageToGo.uploading) {
      return showNextImageEdition(nextIndex);
    }
    setShowEdition({
      index: nextIndex,
      image: imageToGo,
    });
  };

  const showPreviousImageEdition = (currentIndex) => {
    const previousIndex = currentIndex === 0 ? items.length - 1 : currentIndex - 1;
    const imageToGo = items[previousIndex];
    if (imageToGo.uploading) {
      return showPreviousImageEdition(previousIndex);
    }
    setShowEdition({
      index: previousIndex,
      image: imageToGo,
    });
  };

  const replaceImageFromEditor = (oldImage, newImage) => {
    setReplacingImage(true);
    dispatch(
      replaceImage(
        newImage,
        oldImage.order,
        () => {
          setReplacingImage(false);
          notification.success({
            message: 'Image modifiée',
            description: `L'image a été modifiée avec succès`,
            placement: 'bottomRight',
            duration: 3,
          });
        },
        (error) => {
          setReplacingImage(false);
          setErrors([
            ...errors,
            {
              name: oldImage.name,
              message: error,
            },
          ]);
        }
      )
    );
  };

  const sendImageToServer = (file) => {
    const maxCompressedSize = max_file_weight.mo * Math.pow(10, 6);

    compressImage(file, maxCompressedSize).then((compressedImage) =>
      dispatch(
        uploadImage(compressedImage, (file, error) => {
          setErrors([
            ...errors,
            {
              name: file.name,
              message: error.toString(),
            },
          ]);
        })
      )
    );
  };

  const onDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      sendImageToServer(file);
    });
  };

  const onDropRejected = (rejectedFiles) => {
    const errors = [];
    rejectedFiles.forEach((file) => {
      const mainType = file.type.split('/')[0];

      if (mainType !== 'image') {
        errors.push({
          name: file.name,
          message: fileTypeError,
        });
      } else if (file.size > maxOriginalSize) {
        errors.push({
          name: file.name,
          message: maxSizeError,
        });
      }
    });

    if (errors.length) {
      notification.open(formatErrors(errors));
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 50,
        tolerance: 5,
      },
    })
  );

  const reorderImages = (images) => {
    const orderedImages = images.map((image, index) => ({
      ...image,
      order: index + 1,
    }));
    dispatch({
      type: REORDER_IMAGE,
      payload: {
        images: orderedImages,
      },
    });
    return orderedImages;
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.order === active.id);
      const newIndex = items.findIndex((item) => item.order === over.id);

      const imagesMoved = arrayMove(items, oldIndex, newIndex);

      setItems(reorderImages(imagesMoved));
    }
  };

  const handleRemove = (image) => {
    dispatch({
      type: DELETE_IMAGE,
      image_id: image.image_id,
    });
    setItems(reorderImages(items.filter((item) => item.order !== image.order)));
  };

  const defaultImages = [1, 2, 3];

  return (
    <>
      <div className='form-item-header'>
        <p className='h2'>
          {label}
          <Asterisk />
        </p>
        {helptext && <p className='subtext'>{helptext}</p>}
      </div>
      <div className='photos-grid'>
        <ImportPhotoDropzone onDrop={onDrop} onDropRejected={onDropRejected} />
        {items.length > 0 ? (
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={items.map((item) => item.order)}>
              {items.map((image, index) => {
                const key = image.image_id ? image.image_id : image.id;
                return (
                  <PhotoItem
                    key={`${key}-${index}`}
                    classNameBlock='photo-item-block'
                    image={image}
                    handleRemove={handleRemove}
                    activateEdition={() =>
                      setShowEdition({
                        index: index,
                        image: image,
                      })
                    }
                  />
                );
              })}
            </SortableContext>
          </DndContext>
        ) : (
          <>
            {defaultImages.map((number) => (
              <div key={number} className='photo-item-block default-image-item'>
                <DefaultImageIcon />
                <p className='body1 text-grey-medium'>{number}</p>
              </div>
            ))}
          </>
        )}
      </div>
      {showEdition && (
        <Modal open width='90vw' centered footer={null} maskClosable={false} onCancel={() => setShowEdition(null)}>
          <ImageEditor
            image={showEdition.image}
            saveImage={replaceImageFromEditor}
            loading={replacingImage}
            goPrevious={() => showPreviousImageEdition(showEdition.index)}
            goNext={() => showNextImageEdition(showEdition.index)}
          />
        </Modal>
      )}
    </>
  );
}

export default connect((state) => ({
  images: state.products.form_fields[AD_DETAILS_API_IDS.IMAGES.IMAGES],
  validationRules: state.products.validation_rules,
}))(PhotosGrid);
