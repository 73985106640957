import React from 'react';

import '../common/Authentication.scss';
import LoginForm from './LoginForm';

export default function Login() {
  return (
    <>
      <div className='authentication-block'>
        <div className='title-block'>
          <h1 className='title'>Bienvenue sur Labo</h1>
          <p className='h4'>Votre outil de création d'annonces et de suivi d'activités.</p>
        </div>
        <LoginForm />
      </div>
    </>
  );
}
