import Icon from '@ant-design/icons';
import { Flex } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import CustomCheckmarkIcon from '~/assets/svg/icon-checkmark.svg?react';
import CustomCreditCardIcon from '~/assets/svg/icon-credit-card.svg?react';
import CustomShippingTruckIcon from '~/assets/svg/icon-shipping-truck.svg?react';
import CustomUnhappyIcon from '~/assets/svg/icon-smiley-unhappy.svg?react';
import ExternalLink from '~/components/shared/ExternalLink/ExternalLink';
import { MerchantExternalRouting } from '~/helpers/general';

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return dayjs(date).format('D MMM');
};

function ConversationOrderSummary({ activeMerchantSlug, conversation }) {
  const order = conversation.order;
  return (
    <Flex vertical gap={8} className='body1'>
      <p className='body2'>À propos de la commande :</p>
      <p>
        Prénom, Nom : {conversation.user.first_name} {conversation.user.last_name}
      </p>
      <p>N° de commande : {order.id_number}</p>
      <p>Montant: {order.amount}€</p>
      <Flex gap={8} align='center'>
        <Flex gap={4} align='center'>
          <Icon component={CustomCreditCardIcon} />
          <p>Commandée : {formatDate(order.created_on)}</p>
        </Flex>
        <span className='text-grey'>/</span>
        {order.confirmed_on && (
          <>
            <Flex gap={4} align='center'>
              <Icon component={CustomCheckmarkIcon} />
              <p>Confirmée : {formatDate(order.confirmed_on)}</p>
            </Flex>
            <span className='text-grey'>/</span>
          </>
        )}
        {order.sent_on && (
          <>
            <Flex gap={4} align='center'>
              <Icon component={CustomShippingTruckIcon} />
              <p>Expédiée : {formatDate(order.sent_on)}</p>
            </Flex>
            {order.tracking_url && <ExternalLink href={order.tracking_url} title='Suivre le colis' />}
            <span className='text-grey'>/</span>
          </>
        )}
      </Flex>
      <Flex gap={8} align='center'>
        {order.first_incident_on && (
          <Flex gap={4} align='center'>
            <CustomUnhappyIcon width='1em' height='1em' />
            <p>Réclamation ouverte le : {formatDate(order.first_incident_on)}</p>
          </Flex>
        )}
        <ExternalLink href={MerchantExternalRouting.orderDetail(activeMerchantSlug, order.id)} type='dark' title='Détails de la commande' />
      </Flex>
    </Flex>
  );
}

export default connect(({ session }) => ({ activeMerchantSlug: session.active_merchant.merchant_slug }))(ConversationOrderSummary);
