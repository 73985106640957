import { CloseOutlined } from '@ant-design/icons';
import { Badge, Button, DatePicker, Flex, Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import CustomSelect from '~/components/shared/CustomSelect/CustomSelect';
import { USER_DATE_FORMAT } from '~/helpers/general';
import { SEARCH_PARAMETERS_API_IDS, STATUS_API_IDS } from '~/helpers/order/catalogue';

import { filterParams } from '../SearchBar';
import './FiltersPanel.scss';

const { RangePicker } = DatePicker;

const labelStatusMap = {
  [STATUS_API_IDS.AUTHORIZED]: 'Autorisée',
  [STATUS_API_IDS.CONFIRMED]: 'Confirmée',
  [STATUS_API_IDS.PROCESSED]: 'Traitée',
};

export default function FiltersPanel({ params, changeParams }) {
  const [showFilters, setShowFilters] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    let changed = false;
    const newParams = { ...params };
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (value !== undefined && value !== null && value !== '') {
        changed = true;
        if (key === 'created_on') {
          const [start, end] = value;
          if (start) {
            newParams[SEARCH_PARAMETERS_API_IDS.CREATION_DATE_MIN] = start.format(USER_DATE_FORMAT);
          }
          if (end) {
            newParams[SEARCH_PARAMETERS_API_IDS.CREATION_DATE_MAX] = end.format(USER_DATE_FORMAT);
          }
        } else {
          newParams[key] = value;
        }
      }
    });
    if (changed) {
      changeParams(newParams);
    }
    setShowFilters(false);
  };

  return (
    <>
      <Badge className='btn-filters-wrapper' count={filterParams(params).length}>
        <Button onClick={() => setShowFilters(true)}>Voir tous les filtres</Button>
      </Badge>
      <Modal open={showFilters} onCancel={() => setShowFilters(false)} footer={null} className='filter-panel' closeIcon={null} width={800}>
        <Flex vertical gap={16}>
          <Flex className='filter-panel-header' align='center' justify='space-between'>
            <h2 className='h2'>Filter la recherche</h2>
            <Button type='text' className='btn-icon text-primary' size='large' icon={<CloseOutlined />} onClick={() => setShowFilters(false)} />
          </Flex>
          <Form form={form} name='orders-filters' onFinish={onFinish} layout='vertical'>
            <Flex vertical gap={24}>
              <Flex className='column-xs' gap={24} align='center'>
                <Form.Item name={SEARCH_PARAMETERS_API_IDS.FIRST_NAME} label='Prénom du client' className='form-item-full-width'>
                  <Input />
                </Form.Item>
                <Form.Item name={SEARCH_PARAMETERS_API_IDS.LAST_NAME} label='Nom du client' className='form-item-full-width'>
                  <Input />
                </Form.Item>
              </Flex>

              <Form.Item name={SEARCH_PARAMETERS_API_IDS.USER_EMAIL} label='Adresse mail'>
                <Input />
              </Form.Item>

              <Form.Item name={SEARCH_PARAMETERS_API_IDS.ITEM_SKU_EXACT} label='Numéro SKU'>
                <Input placeholder='Exemple: 978538560567' />
              </Form.Item>

              <Form.Item name={SEARCH_PARAMETERS_API_IDS.ORDER_ID_NUMBER} label='Numéro de commande'>
                <Input placeholder='Exemple: 240115E111679355' />
              </Form.Item>

              <Flex className='column-xs' gap={24} align='center'>
                <Form.Item name={SEARCH_PARAMETERS_API_IDS.STATUS} label='Statut' className='form-item-full-width'>
                  <CustomSelect
                    placeholder='Sélectionnez le statut de la commande recherchée'
                    options={Object.keys(STATUS_API_IDS).map((key) => ({
                      label: labelStatusMap[STATUS_API_IDS[key]],
                      value: STATUS_API_IDS[key],
                    }))}
                  />
                </Form.Item>
                <Form.Item name='created_on' label='Date de création' className='form-item-full-width'>
                  <RangePicker allowEmpty={[true, true]} placeholder={['Date de début', 'Date de fin']} format={USER_DATE_FORMAT} />
                </Form.Item>
              </Flex>

              <Flex gap={8} justify='end'>
                <Button type='default' onClick={() => form.resetFields()}>
                  Réinitialiser
                </Button>
                <Button type='text' onClick={() => setShowFilters(false)}>
                  Annuler
                </Button>
                <Button type='primary' htmlType='submit'>
                  Appliquer
                </Button>
              </Flex>
            </Flex>
          </Form>
        </Flex>
      </Modal>
    </>
  );
}
