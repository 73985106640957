import { useMutation, useQuery } from '@tanstack/react-query';
import { paramsToURL } from '~/components/screens/Conversations/Conversations';
import { api } from '~/services/api';

import { treatApiError } from './utils';

const TIMEOUT = 1000 * 60 * 5; // 5 minutes
const SHORT_TIMEOUT = 1000 * 60; // 1 minute
const STALE_TIME = 1000 * 60 * 15; // 15 minutes

export const useConversations = (merchantId, params) => {
  return useQuery({
    queryKey: ['conversations', merchantId, params],
    queryFn: () =>
      api.get(`/${merchantId}/conversation/${paramsToURL(params)}`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    refetchInterval: TIMEOUT,
    staleTime: STALE_TIME,
  });
};

export const useConversationsCountToRead = (merchantId) => {
  return useQuery({
    queryKey: ['conversationsCountToRead', merchantId],
    queryFn: () =>
      api.get(`/${merchantId}/conversation/to_read_count/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    refetchInterval: SHORT_TIMEOUT,
  });
};

export const useConversation = (conversationId) => {
  return useQuery({
    queryKey: ['conversation', conversationId],
    queryFn: () =>
      api.get(`/conversation/${conversationId}/?mark_as_read=true`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    refetchInterval: TIMEOUT,
    staleTime: STALE_TIME,
  });
};

export const useSendMessage = (conversationId, queryClient) => {
  return useMutation({
    mutationFn: (data) =>
      api
        .post(`/conversation/${conversationId}/message/`, {
          body: data,
        })
        .then((res) => {
          if (res.success) {
            return res.data;
          }
          treatApiError(res);
        }),
    onSuccess: (data) => {
      queryClient.setQueryData(['conversation', data.id], data);
      queryClient.setQueryData(['conversations', data.merchant.toString()], (oldData) => {
        return oldData.map((conversation) => {
          if (conversation.id === data.id) {
            return { ...conversation, last_message: data.messages[data.messages.length - 1].content };
          }
          return conversation;
        });
      });
    },
  });
};

export const useCloseConversation = (conversationId, queryClient) => {
  return useMutation({
    mutationFn: () =>
      api.patch(`/conversation/${conversationId}/close/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(['conversation', data.id], data);
    },
  });
};
