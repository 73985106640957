import { Button, Flex, Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function LeavingConfirmationModal({ isListening }) {
  const history = useHistory();

  const [show, setShow] = useState(false);
  const currentTransaction = useRef(null);

  const unblockRef = useRef(null);

  useEffect(() => {
    unblockRef.current = history.block((tx) => {
      if (isListening && (tx.pathname !== history.location.pathname || tx.hash === '')) {
        currentTransaction.current = tx;
        setShow(true);
        return false;
      }
    });
    return () => {
      unblockRef.current();
      if (currentTransaction.current) {
        history.push(currentTransaction.current.pathname);
      }
    };
  }, [history, isListening]);

  const handleQuitWithoutSaving = () => {
    setShow(false);
    unblockRef.current();
    history.push(currentTransaction.current.pathname, currentTransaction.current.state);
  };

  const handleCancel = () => {
    setShow(false);
  };

  return (
    <Modal open={show} closeIcon={null} footer={null} maskClosable={false}>
      <Flex vertical gap={32}>
        <Flex vertical gap={16}>
          <p className='h2'>Souhaitez-vous quitter cette page ?</p>
          <p className='body1'>Toutes les modifications non enregistrées seront perdues.</p>
        </Flex>
        <Flex vertical gap={16}>
          <Button type='primary' onClick={handleQuitWithoutSaving}>
            Quitter la page sans sauvegarder
          </Button>
          <Button onClick={handleCancel}>Annuler</Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
