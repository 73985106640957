import React from 'react';

import './InfoCard.scss';

function InfoCard({ title, description, footer, footerWrapperClassname = null }) {
  return (
    <div className='info-card'>
      <div className='main-info-card-wrapper'>
        <p className='h4'>{title}</p>
        <p className='body1 main-info-description'>{description}</p>
      </div>
      <div className={`footer-info-card-wrapper${footerWrapperClassname ? ` ${footerWrapperClassname}` : ''}`}>{footer}</div>
    </div>
  );
}

export default InfoCard;
