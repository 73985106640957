/* Insert very general constants and functions here, used in multiple parts of the app */
/* Constants and functions used only in specific parts of the project must be written in specific files (catalogue.js, home.js, etc) */

export function createURLfromSubdomain(subdomain, withProtocol = true) {
  const URL_CURRENT_PROTOCOL = 'https://';
  const URL_WITHOUT_PROTOCOL = subdomain + '.label-emmaus.co';

  if (withProtocol) {
    return URL_CURRENT_PROTOCOL + URL_WITHOUT_PROTOCOL;
  }
  return URL_WITHOUT_PROTOCOL;
}

// eslint-disable-next-line
export const IS_IN_PRODUCTION = typeof __PROD__ !== 'undefined' && __PROD__ && !window.location.href.includes('labodev');
export const IS_LOCAL = window.location.href.includes('localhost');

export const getPrefixPixrUrl = (suffix) => {
  if (IS_IN_PRODUCTION) {
    return `https://www.label-emmaus.co/media/ext/${suffix}/`;
  }
  return createURLfromSubdomain('dev') + `/media/ext/${suffix}/`;
};

export const getImageUrl = (url, size = null, croptype = null) => {
  const pixr_suffix = size && croptype ? `${size}x${size}_${croptype}` : size ? `${size}x${size}` : 'orig';

  if (url && url.includes('orig') && url.includes('https://')) {
    return url.replace('orig', pixr_suffix);
  }
  if (url && !url.includes('label-emmaus')) {
    return getPrefixPixrUrl(pixr_suffix) + url.replace('https://', '');
  }
  return url;
};

// eslint-disable-next-line
export const LABEL_URL = IS_IN_PRODUCTION ? createURLfromSubdomain('www') : createURLfromSubdomain('dev');
export const LABO_URLS = [createURLfromSubdomain('labodev'), createURLfromSubdomain('labo')];
export const SALESFORCE_BASE_URL = IS_IN_PRODUCTION ? 'https://label-emmaus.my.site.com/s/' : 'https://label-emmaus--devlot3.sandbox.my.site.com/s/';
export const USER_DATE_FORMAT = 'DD-MM-YYYY';
export const API_DATE_FORMAT = 'MM-DD-YYYYTHH:mm:ss';

export const ERRORS = {
  FORBIDDEN: {
    // TODO: vérifier qu'une requête a échoué à cause de l'invalidité du cookie
    // via le status 401 Unthorized (** En attente du dév chez Pimentech **)
    CODE: '403',
  },
  NO_SKU_AT_CREATION: {
    CODE: 'NO_SKU_AT_CREATION',
  },
};

const MERCHANT_EXTERNAL_BASE_URL = IS_IN_PRODUCTION
  ? 'https://emmaus.merchant.izberg-marketplace.com'
  : 'https://emmaus-sandbox.merchant.izberg-marketplace.com';

const MERCHANT_EXTERNAL_ROUTING = {
  orders: '/orders/manage',
  orderDetail: '/orders/details/',
  analytics: '/analytics',
  finance: '/sellers/withdrawals',
};

export class MerchantExternalRouting {
  static orders(slug) {
    return MerchantExternalRouting.createUrl('orders', slug);
  }

  static analytics(slug) {
    return MerchantExternalRouting.createUrl('analytics', slug);
  }

  static finance(slug) {
    return MerchantExternalRouting.createUrl('finance', slug);
  }

  static orderDetail(slug, merchantOrderId) {
    return MerchantExternalRouting.createUrl('orderDetail', slug) + merchantOrderId;
  }

  static createUrl(routing, slug) {
    return `${MERCHANT_EXTERNAL_BASE_URL}/${slug}${MERCHANT_EXTERNAL_ROUTING[routing]}`;
  }
}

export const WIZVILLE_EXTENAL_LINK = 'https://app.wizville.fr/login';
export const HELP_EXTERNAL_LINK = 'https://sites.google.com/label-emmaus.co/intranetdesvendeurs/';
export const MATTERMOST_EXTERNAL_LINK = 'https://team.pimentech.net/vendeurs/channels/town-square';

const ROUTING = {
  login: '/connexion',
  passwordResetRequest: '/password-reset-request',
  passwordReset: '/reset-password',
  home: '/accueil',
  catalogue: '/annonce/catalogue',
  adCreation: '/annonce/creation',
  adEdition: '/annonce/edition',
  orders: '/commande/catalogue',
  orderDetails: '/commande/details',
  conversations: '/conversations',
};

/**
 * A utility class for dynamically generating URL routes based on a predefined configuration.
 *
 * The configuration : {@link ROUTING}
 * @class
 * @example
 *
 * RoutingUrl.login();
 * RoutingUrl.catalogue([]);
 * RoutingUrl.adEdition([':product_id']);
 */
export class RoutingUrl {
  static login = () => '';
  static passwordResetRequest = () => '';
  static passwordReset = () => '';
  static home = () => '';
  static catalogue = () => '';
  static adCreation = () => '';
  static adEdition = (additionalEnds = [], params = {}) => additionalEnds && params && '';
  static orders = (additionalEnds = [], params = {}) => additionalEnds && params && '';
  static orderDetails = (additionalEnds = [], params = {}) => additionalEnds && params && '';
  static conversations = (additionalEnds = [], params = {}) => additionalEnds && params && '';
}

Object.keys(ROUTING).forEach((key) => {
  RoutingUrl[key] = function (additionalEnds = [], params = {}) {
    let url = ROUTING[key];
    if (additionalEnds.length > 0) {
      url += '/' + additionalEnds.join('/');
    }
    if (params && Object.keys(params).length > 0) {
      url += '?';
      for (const [key, value] of Object.entries(params)) {
        url += key + '=' + value + '&';
      }
      url = url.substring(0, url.length - 1);
    }
    return url;
  };
});

const GOOGLE_SHEETS_DOC_ID = IS_IN_PRODUCTION ? '15AXkkfPJbg-cCh7CKIbZYZyEGgHHdgxXlfu5A4SVAhg' : '1BkKuTqtI1gjHMziOu8SSwIQMpjSrT4uuAGzk30Sf9n4';
export const createGoogleSheetUrl = (ressourceId) =>
  `https://sheets.googleapis.com/v4/spreadsheets/${GOOGLE_SHEETS_DOC_ID}/values/${ressourceId}?alt=json&key=AIzaSyDEjUOShgJIk7UsYlS6Cmm3_GYE9eQuqJY`;

export function debounce(callback, delay) {
  let timer;
  return function () {
    let args = arguments;
    let context = this;
    clearTimeout(timer);
    timer = setTimeout(function () {
      callback.apply(context, args);
    }, delay);
  };
}

export const emailPattern = /^.[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.\-/!$@])[a-zA-Z\d.\-/!$@'%&<>#[\]()+"*,:;?\\^`|~]{8,}$/;

export function isEmail(emailPretender) {
  if (!isDefinedAndIsNotEmptyString(emailPretender)) {
    return false;
  }
  const matcher = /^.[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return matcher.test(emailPretender);
}

// SQL-like Example: select COLOR of AD_STATUS where API_ID == 'active' <=> findValueOfJSONWhereOtherAttributeEquals('COLOR', AD_STATUS, 'API_ID', 'active')
export function selectValueOfChildJSONWhereOtherAttributeEquals(requestedAttribute, parentJsonObject, otherAttribute, otherAttributeValue) {
  for (const childObj in parentJsonObject) {
    if (parentJsonObject[childObj][otherAttribute] === otherAttributeValue) {
      return parentJsonObject[childObj][requestedAttribute];
    }
  }
  return '';
}

export function isDefinedAndIsNotEmptyString(value) {
  return value && value !== '';
}

export function valueIfNotNullElseEmptyString(value) {
  if (isDefinedAndIsNotEmptyString(value)) {
    return value;
  }
  return '';
}

export function appendParamsToEndpoint(url, params) {
  if (!params) return url;
  let entries = Object.entries(params);
  if (entries.length === 0) return url;
  if (url.indexOf('?') === -1) {
    url += '?';
  }

  let q = valueIfNotNullElseEmptyString(params.q);
  let sku = valueIfNotNullElseEmptyString(params.sku);
  let emplacement = valueIfNotNullElseEmptyString(params.emplacement);

  if (q !== '' || sku !== '' || emplacement !== '') {
    url += '&q=';
    if (q !== '') {
      url += q;
    } else {
      url += 'sku:';
      if (sku !== '') {
        url += sku;
      }
      if (emplacement !== '') {
        url += '/' + emplacement;
      }
    }
  }

  entries.forEach(([key, value]) => {
    if (!['q', 'sku', 'emplacement'].includes(key) && isDefinedAndIsNotEmptyString(value)) {
      url += '&' + key + '=' + value;
    }
  });

  return url;
}

export function pushIfNotIncluded(array, element) {
  if (!array.includes(element)) {
    array.push(element);
  }
}
