export const SEARCH_PARAMETERS_API_IDS = {
  ORDER_ID: 'order_id_number',
  STATUS: 'status',
};

export const STATUS_API_IDS = {
  OPEN: 'open',
  CLOSED: 'closed',
  ARCHIVED: 'archived',
};

export const defaultParams = {
  [SEARCH_PARAMETERS_API_IDS.STATUS]: [STATUS_API_IDS.OPEN, STATUS_API_IDS.CLOSED],
};
