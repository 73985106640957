import { Flex } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import { getFileIconForAttachment } from '../SendMessage/SendMessage';

const messageTypeMapper = {
  customer: {
    position: 'self-start',
    backgroundColor: 'bg-lila-background',
  },
  merchant: {
    position: 'self-end',
    backgroundColor: 'bg-creme-background',
  },
  default: {
    position: 'flex-start',
    backgroundColor: 'bg-grey-light',
  },
};

const getPositionOfMessage = (type) => {
  return messageTypeMapper[type]?.position || messageTypeMapper.default.position;
};

const getBackgroundColorOfMessage = (type) => {
  return messageTypeMapper[type]?.backgroundColor || messageTypeMapper.default.backgroundColor;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return dayjs(date).format('dddd D MMM HH:mm');
};

function Messages({ messages }) {
  return (
    <div className='h-[50vh] overflow-auto w-full flex flex-col-reverse'>
      <Flex vertical gap={16}>
        {messages.map((message, index) => (
          <Flex key={index} vertical gap={8} className={`${getPositionOfMessage(message.type)} max-w-[60%]`}>
            <Flex className={`flex rounded-md p-m ${getBackgroundColorOfMessage(message.type)}`} vertical gap={8}>
              <p className='body1 break-words'>{message.content}</p>
            </Flex>
            {message.attachments.length > 0 && (
              <Flex gap={10} align='center'>
                {message.attachments.map((attachment) => (
                  <a key={attachment.name} className='flex flex-col gap-xs w-[60px] cursor-pointer' href={attachment.url} target='_blank' rel='noreferrer'>
                    <div className='rounded-sm bg-lila-background h-[60px] flex justify-center items-center'>{getFileIconForAttachment(attachment.type)}</div>
                    <p className='caption1 text-grey-medium text-ellipsis whitespace-nowrap overflow-hidden'>{attachment.name}</p>
                  </a>
                ))}
              </Flex>
            )}
            <p className={`body1 text-grey-medium ${getPositionOfMessage(message.type)}`}>{formatDate(message.created_on)}</p>
          </Flex>
        ))}
      </Flex>
    </div>
  );
}

export default Messages;
