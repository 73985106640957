import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { RoutingUrl } from '~/helpers/general';

import '../common/Authentication.scss';
import PasswordResetRequestForm from './PasswordResetRequestForm';

export default function PasswordResetRequest() {
  return (
    <>
      <div className='authentication-block'>
        <Link to={RoutingUrl.login()}>
          <Button type='link' icon={<ArrowLeftOutlined />}>
            Retour à l'identification
          </Button>
        </Link>
        <div className='title-block'>
          <h2 className='h2'>Réinitialisation de mot de passe</h2>
          <p className='body1'>
            Veuillez renseigner l'adresse e-mail utilisée pour vous connecter à LaBO.
            <br />
            Nous vous enverrons un e-mail contenant un lien pour réinitialiser votre mot de passe.
          </p>
        </div>
        <PasswordResetRequestForm />
      </div>
    </>
  );
}
