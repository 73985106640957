import { Flex, InputNumber, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { HANDLE_CHANGE } from '~/store/reducers/products';

import './Price.scss';
import SimilarAds from './SimilarAds/SimilarAds';

function cleanNumberValue(val) {
  if (val === '') {
    return '';
  }
  const cleanedVal = val.toString().replace(',', '.');
  const parsedValue = parseFloat(cleanedVal);
  return isNaN(parsedValue) ? '' : parsedValue;
}

function Price({ dispatch, component, editionMode = false, defaultPrice, defaultPreviousPrice }) {
  const [price, setPrice] = useState(defaultPrice);

  const { key, validation_rules } = component;
  const { required } = validation_rules;

  const changePrice = (val) => {
    if (val === price) {
      return;
    }

    setPrice(val);
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: AD_DETAILS_API_IDS.TECHNICAL_INFO.PRICE,
        value: val,
      },
    });
  };

  return (
    <>
      <div className='form-item-header'>
        <Flex gap={8} align='center'>
          <p className='h2'>Prix</p>
          {required && <Asterisk />}
          <Tooltip
            title={
              <>
                Fixer un prix attractif pour votre futur·e client·e.
                <br />
                Vous pourrez avec une offre Label Affaire ou un prix réduit une fois votre article publié.
              </>
            }
            trigger={editionMode ? [] : 'hover'}
          >
            <Tag color='processing' className='body1'>
              Nouveau
            </Tag>
          </Tooltip>
        </Flex>
      </div>
      <InputNumber
        className='input-price'
        id={key}
        value={price}
        onStep={(value) => changePrice(cleanNumberValue(value))}
        onBlur={(elem) => changePrice(cleanNumberValue(elem.target.value))}
        addonAfter='€'
        decimalSeparator=','
        step={1}
        controls
      />
      {editionMode && defaultPreviousPrice > 0 && <p className='body1 text-grey-medium'>Prix barré (affiché sur l’annonce) :{defaultPreviousPrice}€</p>}
      <SimilarAds />
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const defaultPrice = form_fields[AD_DETAILS_API_IDS.TECHNICAL_INFO.PRICE];
  const defaultPreviousPrice = form_fields[AD_DETAILS_API_IDS.TECHNICAL_INFO.CROSSED_PRICE];

  return {
    defaultPrice,
    defaultPreviousPrice,
  };
})(Price);
