import Icon from '@ant-design/icons';
import { Flex, Spin, Tag } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import CustomTimeIcon from '~/assets/svg/icon-time.svg?react';
import { useConversations } from '~/hooks/useConversations';

import ConversationDetails from './ConversationDetails/ConversationDetails';

const getResponseNeededColor = (responseNeededIn) => {
  if (responseNeededIn.late || !responseNeededIn.days) {
    return 'error';
  }
  if (responseNeededIn.days > 0 && responseNeededIn.days < 3) {
    return 'warning';
  }
  return 'default';
};

const getFormattedResponseNeededIn = (responseNeededIn) => {
  // If any of the values is negative, display it as urgent
  if (responseNeededIn.late) {
    return 'Retard';
  }
  if (responseNeededIn.days) {
    return `${responseNeededIn.days}j`;
  }
  return `${responseNeededIn.hours}h`;
};

function ConversationsList({ params, activeMerchantId, selectedConversation, setSelectedConversation }) {
  const { isLoading, data } = useConversations(activeMerchantId, params);

  if (isLoading) {
    return (
      <Flex justify='center' align='center' className='h-full'>
        <Spin size='large' />
      </Flex>
    );
  }

  return (
    <>
      <Flex gap={32} className='max-h-[75vh]'>
        <Flex className='w-[200px]  overflow-auto' vertical gap={16}>
          {data?.length > 0 && <p className='body2'>Réponse requise avant : </p>}
          <Flex vertical>
            {data?.map((conversation) => (
              <Flex
                key={conversation.id}
                onClick={() => {
                  setSelectedConversation(conversation.id);
                  conversation.read_by_merchant = true;
                }}
                vertical
                gap={4}
                // We have to cancel other borders due to preflight not setup
                className={`p-m border-b border-x-0 border-t-0 border-solid border-grey ${selectedConversation === conversation.id ? 'bg-creme-background' : ''}`}
              >
                <Flex justify='space-between'>
                  <p className={conversation.read_by_merchant ? 'body1' : 'body2'}>
                    {conversation.user.first_name} {conversation.user.last_name}
                  </p>
                  {conversation.response_needed_in && (
                    <Tag className='flex gap-xs items-center light round' color={getResponseNeededColor(conversation.response_needed_in)}>
                      <Icon component={CustomTimeIcon} />
                      {getFormattedResponseNeededIn(conversation.response_needed_in)}
                    </Tag>
                  )}
                </Flex>
                <p className='caption1 overflow-hidden text-ellipsis line-clamp-1'>{conversation.last_message}</p>
              </Flex>
            ))}
          </Flex>
        </Flex>

        {selectedConversation && <ConversationDetails conversationId={selectedConversation} />}
      </Flex>
    </>
  );
}

export default connect(({ session }) => ({ activeMerchantId: session.active_merchant.id }))(ConversationsList);
