import { Button, Flex, Modal, Radio } from 'antd';
import { useAtom } from 'jotai';
import React, { useRef, useState } from 'react';
import { adsCatalogueDataWithCookieAtom } from '~/atoms';
import ExpandableContent from '~/components/shared/ExpandableContent/ExpandableContent';
import { adsCatalogueDataElasticValue, adsCatalogueDataMongoValue } from '~/helpers/cookies';

function AdsCatalogueSettings() {
  const [adsCatalogueData, setAdsCatalogueData] = useAtom(adsCatalogueDataWithCookieAtom);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const paramValue = useRef();
  return (
    <ExpandableContent title="Ma liste d'annonces" titleClassName='body1' withArrow>
      <div className='py-l'>
        <Radio.Group
          value={adsCatalogueData}
          onChange={(e) => {
            paramValue.current = e.target.value;
            setConfirmationModal(true);
          }}
        >
          <Flex className='ads-catalogue-params-wrapper' vertical gap={16}>
            <Radio value={adsCatalogueDataMongoValue}>Par défaut : Affiche immédiatement les annonces créées manuellement ou par API.</Radio>
            <Radio value={adsCatalogueDataElasticValue}>
              Affichage vendeur par flux : Affiche les annonces créées par import de flux. Les annonces créées manuellement apparaîtront avec une latence.
            </Radio>
          </Flex>
        </Radio.Group>
        <Modal centered open={confirmationModal} closeIcon={null} footer={null} maskClosable={false}>
          <Flex vertical gap={32}>
            <p className='h2'>Souhaitez-vous appliquer ce paramètre ?</p>
            <Flex vertical gap={16}>
              <Button
                type='primary'
                onClick={() => {
                  setAdsCatalogueData(paramValue.current);
                  setConfirmationModal(false);
                }}
              >
                Appliquer le paramètre
              </Button>
              <Button onClick={() => setConfirmationModal(false)}>Annuler</Button>
            </Flex>
          </Flex>
        </Modal>
      </div>
    </ExpandableContent>
  );
}

export default AdsCatalogueSettings;
