/* eslint-disable */
import { Button, Divider, Flex, Tag } from 'antd';
import React from 'react';
import { getImageUrl } from '~/helpers/general';
import { colorStatusMapping } from '~/helpers/order/catalogue';
import { useOrderItem } from '~/hooks/useOrderDetails';

import './OrderItems.scss';

function OrderItemDetailAd({ item, index, order, activeMerchantId }) {
  const { id, item_image_url, name, sku, quantity, status_localized, shipping, amount_vat_included } = item;
  const { isLoading, data } = useOrderItem(id, activeMerchantId);

  if (isLoading) return null;
  const { stock } = data;

  return (
    <>
      <Flex key={id}>
        <div className='list-cell-image'>
          <img className='ad-img' src={getImageUrl(item_image_url, 200, 'cropcenter')} alt={`Photo article ${index}`} />
        </div>
        <Flex className='list-cell-ad' vertical gap={16}>
          <Button type='link' className='offer-link'>
            {name}
          </Button>
          <Flex className='custom-list' vertical gap={8} justify='center'>
            <p className='body1'>SKU : {sku}</p>
            <p className='body1'>ID commande : {order.order.id_number}</p>
            <p className='body1'>Stock après envoi de cette commande : {stock - quantity > 0 ? stock - quantity : 0} restant</p>
            <p className='body1'>
              Statut :
              <Tag className='item-status light capitalize-first-letter' color={colorStatusMapping[status_localized]}>
                {status_localized}
              </Tag>
            </p>
            {shipping && <p className='body1'>Livraison : {shipping.name}</p>}
          </Flex>
        </Flex>
        <p className='list-cell-quantity body1'>{quantity}</p>
        <p className='list-cell-amount body1'>{amount_vat_included}€</p>
      </Flex>
      <Divider key={`divider-${id}`} />
    </>
  );
}

function OrderCommissions({ order }) {
  const { commissions } = order;

  if (commissions.length === 0) return null;

  const totalCommission = commissions.reduce((acc, commission) => acc + parseFloat(commission.total_price), 0);
  const gains = order.amount_vat_included - totalCommission;
  return (
    <>
      <Divider />
      <Flex justify='space-between' align='center'>
        <p className='body1'>Total des commissions</p>
        <p className='body1'>-{totalCommission}€</p>
      </Flex>
      <Divider />
      <Flex justify='space-between' align='center'>
        <p className='body1'>Vos gains</p>
        <p className='h4'>{gains}€</p>
      </Flex>
    </>
  );
}

export default function OrderItems({ order, activeMerchantId }) {
  const beforePromotionHt = parseFloat(order.amount) - parseFloat(order.discount_amount);
  const beforePromotionTtc = parseFloat(order.amount_vat_included) - parseFloat(order.discount_amount_vat_included);
  return (
    <>
      <Flex className='panel bordered' vertical gap={24}>
        <p className='h2'>Détails de la commande</p>
        <Flex className='order-items-list-head' vertical gap={8}>
          <Flex className='list-head-items-wrapper'>
            <p className='body1 list-cell-image'>Image</p>
            <p className='body1 list-cell-ad'>Annonce</p>
            <p className='body1 list-cell-quantity'>Quantité</p>
            <p className='body1 list-cell-amount'>Montant TTC</p>
          </Flex>
          <Divider />
        </Flex>
        {order.items.map((item, index) => (
          <OrderItemDetailAd key={item.id} item={item} index={index} order={order} activeMerchantId={activeMerchantId} />
        ))}
        <Flex className='list-total-wrapper' vertical>
          <Flex justify='space-between' align='center'>
            <p className='body1'>Livraison</p>
            <p className='body1'>{order.shipping}€</p>
          </Flex>
          <Flex justify='space-between' align='center'>
            <p className='body1'>Avant promotion HT</p>
            <p className='body1'>{beforePromotionHt}€</p>
          </Flex>
          <Flex justify='space-between' align='center'>
            <p className='body1'>Avant promotion TTC</p>
            <p className='body1'>{beforePromotionTtc}€</p>
          </Flex>
          <Flex justify='space-between' align='center'>
            <p className='body1'>Total</p>
            <p className='body1'>{order.amount_vat_included}€</p>
          </Flex>
        </Flex>
        <OrderCommissions order={order} />
      </Flex>
    </>
  );
}
