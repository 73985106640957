class CookieManager {
  constructor(key) {
    this.key = key;
  }

  getItem(defaultValue = null) {
    const item = localStorage.getItem(this.key);
    if (item === null && defaultValue !== null) {
      this.setItem(defaultValue);
      return defaultValue;
    }
    return JSON.parse(item);
  }

  setItem(value) {
    localStorage.setItem(this.key, JSON.stringify(value));
  }

  removeItem() {
    localStorage.removeItem(this.key);
  }
}

const userKey = 'user';
const collapsedKey = 'collapsed';
const onBoardingTokenKey = 'phaseOnBoarded';
const adsCatalogueDataKey = 'adsCatalogueData';
const favoriteMerchantKey = 'favoriteMerchantId';

export const adsCatalogueDataMongoValue = 'labo_products_mongo';
export const adsCatalogueDataElasticValue = 'labo_products_elastic';

export const userCookieManager = new CookieManager(userKey);
export const collapseCookieManager = new CookieManager(collapsedKey);
export const onBoardingPhaseManager = new CookieManager(onBoardingTokenKey);
export const adsCatalogueDataManager = new CookieManager(adsCatalogueDataKey);
export const favoriteMerchantManager = new CookieManager(favoriteMerchantKey);

export function getUserCookie() {
  return localStorage.getItem(userKey);
}

export function setUserCookie(user) {
  userCookieManager.setItem(user);
}

export function removeUserCookie() {
  userCookieManager.removeItem();
}

export function setCollapseCookie(value) {
  collapseCookieManager.setItem(value);
}

export function getCollapseCookie() {
  return collapseCookieManager.getItem('true');
}

export function getOnBoardingPhase() {
  return localStorage.getItem(onBoardingTokenKey);
}

export function setOnBoardingPhase(phase) {
  localStorage.setItem(onBoardingTokenKey, phase);
}

export function getAdsCatalogueData() {
  return adsCatalogueDataManager.getItem(adsCatalogueDataMongoValue);
}

export function setAdsCatalogueData(data) {
  adsCatalogueDataManager.setItem(data);
}

export function getFavoriteMerchantId() {
  return localStorage.getItem(favoriteMerchantKey);
}

export function setFavoriteMerchantId(id) {
  localStorage.setItem(favoriteMerchantKey, id);
}
