import { Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import PhotoroomLogo from '~/assets/svg/logo-photoroom.svg?react';
import { useMerchantSettings } from '~/hooks/useMerchantSettings';

function PhotoroomNotification({ activeMerchantId }) {
  const { isLoading, data } = useMerchantSettings(activeMerchantId);
  if (isLoading) return <Spin spinning fullscreen />;
  const { photoroom_disabled: photoroomDisabled } = data;
  return (
    <>
      <Spin spinning fullscreen />
      {!photoroomDisabled && (
        <a id='photoroom-logo-link' href='https://photoroom.com/' target='_blank' rel='noreferrer'>
          <PhotoroomLogo id='photoroom-logo' />
        </a>
      )}
    </>
  );
}

export default connect(({ session }) => ({ activeMerchantId: session.active_merchant.id }))(PhotoroomNotification);
