import { Flex, Tag } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import SearchInput from '~/components/shared/SearchInput/SearchInput';
import { SEARCH_PARAMETERS_API_IDS, STATUS_API_IDS } from '~/helpers/conversation';
import { useConversations } from '~/hooks/useConversations';

function ConversationSearch({ activeMerchantId, params, changeParams }) {
  const { pending, data: conversations } = useConversations(activeMerchantId, { ...params, [SEARCH_PARAMETERS_API_IDS.STATUS]: STATUS_API_IDS.OPEN });
  const onGoingStatus = [STATUS_API_IDS.OPEN, STATUS_API_IDS.CLOSED];
  const archivedStatus = STATUS_API_IDS.ARCHIVED;

  if (pending) {
    return null;
  }

  const countResponseNeeded = conversations?.filter((c) => c.response_needed_in)?.length || 0;

  const isActive = (status) => {
    return _.isEqual(params[SEARCH_PARAMETERS_API_IDS.STATUS], status) ? ' text-primary border-b-2 border-x-0 border-t-0 border-solid border-primary' : '';
  };

  const changeCurrentTab = (status) => () => {
    if (params[SEARCH_PARAMETERS_API_IDS.STATUS] === status) return;
    changeParams({
      ...params,
      [SEARCH_PARAMETERS_API_IDS.STATUS]: status,
    });
  };

  const searchByOrderNumber = (value) => {
    changeParams({
      ...params,
      [SEARCH_PARAMETERS_API_IDS.ORDER_ID]: value,
    });
  };

  return (
    <Flex vertical gap={8}>
      <h4 className='h4'>Messagerie</h4>
      <Flex gap={24} align='center'>
        <Flex gap={40} align='center' className='w-1/2 shrink-0'>
          <Flex
            gap={12}
            align='center'
            className={`cursor-pointer py-m hover:text-primary${isActive(onGoingStatus)}`}
            onClick={changeCurrentTab(onGoingStatus)}
          >
            <p className='h4'>Réponse requise</p>
            {countResponseNeeded > 0 && (
              <Tag className='round' color='processing'>
                {countResponseNeeded}
              </Tag>
            )}
          </Flex>
          <Flex
            gap={12}
            align='center'
            className={`cursor-pointer py-m hover:text-primary${isActive(archivedStatus)}`}
            onClick={changeCurrentTab(archivedStatus)}
          >
            <p className='h4'>Traités</p>
          </Flex>
        </Flex>
        <SearchInput placeholder='N° de commande' defaultValue={params[SEARCH_PARAMETERS_API_IDS.ORDER_ID]} search={searchByOrderNumber} />
      </Flex>
    </Flex>
  );
}
export default connect(({ session }) => ({ activeMerchantId: session.active_merchant.id }))(ConversationSearch);
