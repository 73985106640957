import Icon from '@ant-design/icons';
import { Button, Divider } from 'antd';
import React from 'react';
import ExternalLinkIcon from '~/assets/svg/icon-external-link.svg?react';
import InfoCard from '~/components/shared/InfoCard/InfoCard';
import { HELP_EXTERNAL_LINK, MATTERMOST_EXTERNAL_LINK } from '~/helpers/general';

import Announcement from './Announcement/Announcement';
import CustomCalendar from './CustomCalendar/CustomCalendar';
import './Home.scss';
import NewOrdersInfo from './NewOrdersInfo/NewOrdersInfo';
import ObjectiveCreateAd from './ObjectiveCreateAd/ObjectiveCreateAd';

export default function Home() {
  return (
    <>
      <section className='home'>
        <h1 className='h1'>Bonjour ! Que voulez-vous faire aujourd'hui ?</h1>
        <div className='content-page-wrapper'>
          <div className='new-orders-item'>
            <NewOrdersInfo />
            <Divider className='home-divider' />
          </div>
          <div className='objective-create-ad-item'>
            <ObjectiveCreateAd />
          </div>
          <div className='calendar-item'>
            <CustomCalendar />
          </div>
          <Announcement />
          <div className='community-item'>
            <InfoCard
              title='Communauté'
              description="Trouver de l'aide auprès des autres vendeur·euse·s ou de l'équipe Label Emmaüs"
              footer={
                <>
                  <Button className='community-link' target='_blank' rel='noopener noreferrer' href={HELP_EXTERNAL_LINK}>
                    Centre d'aide
                    <Icon component={ExternalLinkIcon} />
                  </Button>
                  <Button className='community-link' target='_blank' rel='noopener noreferrer' href={MATTERMOST_EXTERNAL_LINK}>
                    Messagerie
                    <Icon component={ExternalLinkIcon} />
                  </Button>
                </>
              }
              footerWrapperClassname='community-footer-wrapper'
            />
            <Divider className='home-divider' />
          </div>
        </div>
      </section>
    </>
  );
}
