import React from 'react';
import CreateAdButton from '~/components/shared/CreateAdButton/CreateAdButton';

import './ObjectiveCreateAd.scss';

function ObjectiveCreateAd() {
  return (
    <div className='panel bordered shadow'>
      <div className='objective-wrapper'>
        <div className='objective-info'>
          <h2 className='h2'>7 annonces créées par jour</h2>
          <p className='body1'>C'est notre objectif à relever en 2024</p>
        </div>
        <CreateAdButton />
      </div>
    </div>
  );
}

export default ObjectiveCreateAd;
