import { Button, Checkbox, Divider, Flex, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getFavoriteMerchantId, setFavoriteMerchantId } from '~/helpers/cookies';
import { ACTIVE_MERCHANT_UPDATE, USER_LOGOUT } from '~/store/reducers/session';

import AdsCatalogueSettings from './MerchantSettings/AdsCatalogueSettings';
import AdsSettings from './MerchantSettings/AdsSettings';
import PromotionsSettings from './MerchantSettings/PromotionsSettings';

function MyAccount({ activeMerchant, merchants, dispatch }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFavoriteMerchant, setIsFavoriteMerchant] = useState(getFavoriteMerchantId() === activeMerchant.id);

  useEffect(() => {
    setIsFavoriteMerchant(getFavoriteMerchantId() === activeMerchant.id);
  }, [activeMerchant]);

  const merchantsCleaned = merchants.filter((merchant) => merchant.id !== activeMerchant.id);
  const merchantOptions = merchantsCleaned.map((merchant) => ({
    value: merchant.id,
    label: merchant.name,
    slug: merchant.slug,
  }));

  const deconnectUser = () => {
    dispatch({ type: USER_LOGOUT });
    setIsModalOpen(false);
  };

  const changeMerchant = (idMerchant) => {
    dispatch({
      type: ACTIVE_MERCHANT_UPDATE,
      payload: { merchant_id: idMerchant },
    });
  };

  const onChangeFavoriteMerchant = () => {
    setIsFavoriteMerchant(!isFavoriteMerchant);
    if (isFavoriteMerchant) {
      setFavoriteMerchantId(null);
    } else {
      setFavoriteMerchantId(activeMerchant.id);
    }
  };

  const filterOption = (input, option) => {
    const name = option?.label ?? '';
    const slug = option?.slug ?? '';
    const id = option?.value ?? '';

    return name.toLowerCase().includes(input.toLowerCase()) || slug.toLowerCase().includes(input.toLowerCase()) || id.includes(input);
  };

  return (
    <>
      <a
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <span className='body1'>Mon compte</span>
      </a>
      <Modal
        centered
        open={isModalOpen}
        footer={null}
        closeIcon={null}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <div className='flex flex-col gap-l p-m'>
          <h1 className='h1'>Mon compte</h1>
          {merchantOptions.length > 0 && (
            <Flex vertical gap={8}>
              <p className='caption2'>Boutique</p>
              <Select
                size='large'
                listHeight={200}
                showSearch
                value={activeMerchant.name}
                onChange={changeMerchant}
                options={merchantOptions}
                filterOption={filterOption}
              />
            </Flex>
          )}
          <p className='caption2'>Informations</p>
          <Flex vertical gap={8}>
            <p className='body1 flex items-center gap-s'>
              <span className='text-grey-medium'>Nom :</span>
              <span>{activeMerchant.name}</span>
            </p>
            <p className='body1 flex items-center gap-s'>
              <span className='text-grey-medium'>Id marchand :</span>
              <span>{activeMerchant.id}</span>
            </p>
          </Flex>
          {merchantOptions.length > 0 && (
            <Flex gap={8} align='center'>
              <Checkbox checked={isFavoriteMerchant} onChange={onChangeFavoriteMerchant}>
                Définir comme marchand favoris
              </Checkbox>
            </Flex>
          )}
          <Divider />
          <p className='body2'>Paramètres de la boutique</p>
          <AdsCatalogueSettings />
          <Divider />

          <PromotionsSettings />
          <Divider />

          <AdsSettings />
          <Divider />
          <Button block onClick={deconnectUser} type='text'>
            Déconnexion
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default connect((state) => ({
  activeMerchant: state.session.active_merchant,
  merchants: state.session.user.merchants,
}))(MyAccount);
