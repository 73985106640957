import { useMutation, useQuery } from '@tanstack/react-query';
import { api } from '~/services/api';

import { treatApiError } from './utils';

export const PROMOTION_SETTINGS_KEY = 'automatic_promotions';
export const PHOTOROOM_SETTINGS_KEY = 'photoroom_disabled';

export const useMerchantSettings = (merchantId) => {
  return useQuery({
    queryKey: ['merchantSettings', merchantId],
    queryFn: () =>
      api.get(`/${merchantId}/settings/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
  });
};

export const useTogglePhotoroomSettings = (merchantId, queryClient) => {
  return useMutation({
    mutationFn: () =>
      api.patch(`/${merchantId}/photoroom_settings/toggle/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(['merchantSettings', merchantId], (oldData) => ({
        ...oldData,
        [PHOTOROOM_SETTINGS_KEY]: data,
      }));
    },
  });
};

export const useToggleAutoPromotionSetting = (merchantId, queryClient) => {
  return useMutation({
    mutationFn: () =>
      api.patch(`/${merchantId}/automatic_promotions/toggle/`).then((res) => {
        if (res.success) {
          return res.data;
        }
        treatApiError(res);
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(['merchantSettings', merchantId], (oldData) => ({
        ...oldData,
        [PROMOTION_SETTINGS_KEY]: data,
      }));
    },
  });
};
