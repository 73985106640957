import { Button, Col, Flex, Modal, Progress, Row } from 'antd';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Inspirations } from '~/components/screens/AdDetails/CreationEditionForm/Fieldsets';
import CustomSelect from '~/components/shared/CustomSelect/CustomSelect';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { BULK_ACTION_EVENTS } from '~/helpers/catalogue';
import { RoutingUrl } from '~/helpers/general';
import { applyBulkEditionToOneProduct } from '~/services/api.calls';
import { loadProducts } from '~/store/reducers/products/actions';

function BulkEditionModal(props) {
  const { EDIT_STOCK, ACTIVATE, ADD_GOOD_DEAL: ADD_GOOD_DEAL, REMOVE_GOOD_DEAL, EDIT_INSPIRATION, DELETE } = BULK_ACTION_EVENTS;
  const modal_data = {
    [EDIT_STOCK]: {
      title: 'Modifier le stock',
      apply_button_label: 'Enregistrer le stock',
      width: 520,
    },
    [ACTIVATE]: {
      title: 'Activer les annonces',
      apply_button_label: 'Activer',
      width: 520,
    },
    [ADD_GOOD_DEAL]: {
      title: 'Ajouter un bon plan',
      apply_button_label: 'Ajouter un bon plan',
      width: 800,
    },
    [REMOVE_GOOD_DEAL]: {
      title: 'Retirer les bons plans',
      apply_button_label: 'Retirer les bons plans',
      width: 800,
    },
    [EDIT_INSPIRATION]: {
      title: 'Modifier les sélections',
      apply_button_label: 'Modifier les sélections',
      width: 800,
    },
    [DELETE]: {
      title: 'Supprimer les annonces',
      apply_button_label: 'Supprimer',
      width: 520,
    },
  };

  const [state, setState] = useState({
    isBulkActionArrayLoading: false,
    hasBulkActionArrayFinishedLoading: false,
    bulkStockValue: 0,
    progressBarFilledPart: 0,
  });

  const [progressStepsState, progressStepsDispatch] = useReducer(bulkEditionStepIncrementer, { numberOfProgressSteps: 0, bulkActionErrorsList: [] });

  useEffect(() => {
    if (progressStepsState.numberOfProgressSteps !== 0) {
      let { selectedProductIds } = props;
      let singleProgressStepValue = 100 / selectedProductIds.length;
      let progressBarFilledPart = progressStepsState.numberOfProgressSteps * singleProgressStepValue;

      setState({
        ...state,
        progressBarFilledPart,
      });

      if (selectedProductIds.length === progressStepsState.numberOfProgressSteps) {
        progressStepsDispatch({ type: 'reset_progress_step_number' });
        setState({
          ...state,
          isBulkActionArrayLoading: false,
          progressBarFilledPart: 0,
          hasBulkActionArrayFinishedLoading: true,
        });
        props.dispatch(loadProducts(props.searchParams));
      }
    }
  }, [progressStepsState]);

  function bulkEditionStepIncrementer(progressStepsState, action) {
    switch (action.type) {
      case 'increment_progress_step_number':
        return {
          bulkActionErrorsList: action.newError ? [...progressStepsState.bulkActionErrorsList, action.newError] : progressStepsState.bulkActionErrorsList,
          numberOfProgressSteps: progressStepsState.numberOfProgressSteps + 1,
        };
      case 'reset_progress_step_number':
        return {
          ...progressStepsState,
          numberOfProgressSteps: 0,
        };
      default:
        throw Error('Unknown action');
    }
  }

  const addBulkErrorToErrorsList = (productId, response, currentProductData) => {
    let errorMessage = "Une erreur s'est produite";
    if (response && response.message) {
      const error = response?.message?.error;
      if (typeof error === 'string') {
        errorMessage = error;
      } else if (error?.length) {
        errorMessage = error[0]?.msg.join(';');
      } else if (response) {
        errorMessage = response?.toString();
      }
    }
    let newError = {
      sku: currentProductData && currentProductData.sku ? currentProductData.sku : '',
      message: errorMessage,
      id: productId,
    };

    progressStepsDispatch({
      type: 'increment_progress_step_number',
      newError: newError,
    });
  };

  const incrementProgressBar = () => {
    progressStepsDispatch({ type: 'increment_progress_step_number' });
  };

  const applyBulkEditionToAllProducts = () => {
    let { bulkStockValue } = state,
      { selectedProductIds, bulkActionEvent, form_fields, merchant_id } = props;

    setState({
      ...state,
      isBulkActionArrayLoading: true,
    });

    selectedProductIds.forEach((productId) => {
      applyBulkEditionToOneProduct(productId, form_fields, merchant_id, bulkActionEvent, bulkStockValue, incrementProgressBar, addBulkErrorToErrorsList);
    });
  };

  const resetBulkEditionModal = () => {
    setState({
      ...state,
      hasBulkActionArrayFinishedLoading: false,
    });
    props.handleReset();
  };

  const renderBulkEditionModalFooter = () => {
    let { isBulkActionArrayLoading, hasBulkActionArrayFinishedLoading } = state;
    let { bulkActionErrorsList } = progressStepsState;

    if (!isBulkActionArrayLoading) {
      if (hasBulkActionArrayFinishedLoading || bulkActionErrorsList.length > 0) {
        return (
          <Button type='primary' onClick={resetBulkEditionModal}>
            OK
          </Button>
        );
      }
      return (
        <Button type='primary' onClick={applyBulkEditionToAllProducts}>
          {modal_data[props.bulkActionEvent].apply_button_label}
        </Button>
      );
    }
    return null;
  };

  const renderBulkEditionModalContent = () => {
    let { isBulkActionArrayLoading, progressBarFilledPart, hasBulkActionArrayFinishedLoading, bulkStockValue } = state;

    let { bulkActionErrorsList } = progressStepsState;

    const goodDealsOptions = props.all_form_components[AD_DETAILS_API_IDS.PROMOTION.LABEL_AFFAIRE].choices[0].choices.map((goodDeal) => ({
      label: goodDeal.value,
      value: goodDeal.id,
    }));

    const onChangeGoodDeals = (value) => {
      props.dispatch({
        type: 'HANDLE_CHANGE',
        payload: {
          input_key: AD_DETAILS_API_IDS.PROMOTION.LABEL_AFFAIRE,
          value: [
            {
              id: value,
            },
          ],
        },
      });
    };

    if (isBulkActionArrayLoading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Progress
            type='circle'
            size='large'
            percent={progressBarFilledPart}
            format={() => `${progressStepsState.numberOfProgressSteps}/${props.selectedProductIds.length}`}
          />
        </div>
      );
    }

    if (hasBulkActionArrayFinishedLoading) {
      if (bulkActionErrorsList.length > 0) {
        return (
          <div>
            <h2>Une erreur est survenue sur {bulkActionErrorsList.length} annonce(s).</h2>
            <ul>
              {bulkActionErrorsList.map((error, i) => (
                <li key={i}>
                  {error.sku ? 'SKU ' + error.sku : 'ID ' + error.id}: {error.message} - &nbsp;
                  <a href={RoutingUrl.adEdition([error.id])} target='_blank' rel='noopener noreferrer'>
                    Voir l'annonce
                  </a>
                </li>
              ))}
            </ul>
          </div>
        );
      }

      return <div>Vos modifications ont été effectuées avec succès. Elles seront visibles sur Labo dans quelques minutes.</div>;
    }

    switch (props.bulkActionEvent) {
      case EDIT_STOCK:
        return (
          <Row type='flex' justify='center' align='top' gutter={16} className='bulk-content stock-edit'>
            <Col xs={6}>
              <Button
                onClick={() =>
                  setState({
                    ...state,
                    bulkStockValue: state.bulkStockValue > 0 ? state.bulkStockValue - 1 : 0,
                  })
                }
              >
                -
              </Button>
            </Col>
            <Col className='manage_stock__value' xs={12}>
              <div className='manage_stock__content' style={{ margin: 0 }}>
                {bulkStockValue}
              </div>
            </Col>
            <Col xs={6} style={{ textAlign: 'right' }}>
              <Button
                onClick={() =>
                  setState({
                    ...state,
                    bulkStockValue: state.bulkStockValue + 1,
                  })
                }
              >
                +
              </Button>
            </Col>
          </Row>
        );

      case ACTIVATE:
        return (
          <Row type='flex' justify='center' align='top' gutter={16} className='bulk-content activation'>
            <div>Voulez-vous activer les {props.selectedProductIds.length} annonces sélectionnées ?</div>
          </Row>
        );

      case ADD_GOOD_DEAL:
        return (
          <Flex vertical gap={16} className='bulk-content promotion-add'>
            Voulez-vous passer les {props.selectedProductIds.length} annonces sélectionnées en bons plans ?
            <CustomSelect placeholder='Sélectionner les bons plans à appliquer' options={goodDealsOptions} onChange={onChangeGoodDeals} />
          </Flex>
        );

      case REMOVE_GOOD_DEAL:
        return (
          <Row type='flex' justify='center' align='top' gutter={16} className='bulk-content promotion-delete'>
            Voulez-vous retirer les bons plans associés aux {props.selectedProductIds.length} annonces sélectionnées ?
          </Row>
        );

      case EDIT_INSPIRATION:
        return (
          <Flex vertical gap={16} className='bulk-content inspiration-edit'>
            <Inspirations component={props.all_form_components[AD_DETAILS_API_IDS.PROMOTION.INSPIRATIONS]} />
          </Flex>
        );

      case DELETE:
        return (
          <Row type='flex' justify='center' align='top' gutter={16} className='bulk-content activation'>
            <div>Voulez-vous supprimer les {props.selectedProductIds.length} annonces sélectionnées ?</div>
          </Row>
        );

      default:
        return null;
    }
  };

  return (
    <Modal
      maskClosable={false}
      open={true}
      title={modal_data[props.bulkActionEvent].title}
      key={modal_data[props.bulkActionEvent].title}
      onCancel={resetBulkEditionModal}
      footer={renderBulkEditionModalFooter()}
      width={modal_data[props.bulkActionEvent].width}
    >
      {renderBulkEditionModalContent()}
    </Modal>
  );
}

function mapStateToProps(state) {
  let {
    products: { form_fields, all_form_components },
    session: { active_merchant },
  } = state;
  return {
    merchant_id: active_merchant.id,
    form_fields,
    all_form_components,
  };
}

export default connect(mapStateToProps)(BulkEditionModal);
