import { useQueryClient } from '@tanstack/react-query';
import { Flex, Spin, Switch } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import ExpandableContent from '~/components/shared/ExpandableContent/ExpandableContent';
import { PROMOTION_SETTINGS_KEY, useMerchantSettings, useToggleAutoPromotionSetting } from '~/hooks/useMerchantSettings';

function PromotionsSettings({ activeMerchantId }) {
  const queryClient = useQueryClient();
  const togglePromotion = useToggleAutoPromotionSetting(activeMerchantId, queryClient);
  const { isLoading, data } = useMerchantSettings(activeMerchantId);
  if (isLoading) return <Spin />;
  const { [PROMOTION_SETTINGS_KEY]: promotionSetting } = data;

  return (
    <ExpandableContent title='Promotions automatisées' titleClassName='body1' withArrow>
      <Flex vertical gap={16} className='py-l'>
        <p className='body1'>
          Les annonces en ligne depuis plus de 3 mois passeront à -20%, celles de plus de 4 mois à -30% et celles de plus de 6 mois à -50%.
          <br />
          <br />
          Le changement est effectif sous 24h.
        </p>
        <Flex gap={8}>
          <Switch checked={promotionSetting} onChange={() => togglePromotion.mutate()} loading={togglePromotion.isPending} />
          <p className='body1'>Gérer automatiquement mes promotions</p>
        </Flex>
      </Flex>
    </ExpandableContent>
  );
}

export default connect(({ session }) => ({ activeMerchantId: session.active_merchant.id }))(PromotionsSettings);
