import { Flex, Input, Radio, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import AlertBasicServerError from '~/components/shared/AlertBasicServerError/AlertBasicServerError';
import Asterisk from '~/components/shared/Asterisk/Asterisk';
import { AD_DETAILS_API_IDS } from '~/helpers/adDetails';
import { useGetCategoryAttributes } from '~/hooks/useGetCategoryAttributes';
import { HANDLE_CHANGE } from '~/store/reducers/products';

import ModalStateDescription from './ModalStateDescription';

function State({ dispatch, categories, stateValue, statePrecisionValue }) {
  const currentCategory = categories[categories.length - 1].id;

  const { isLoading, data } = useGetCategoryAttributes(currentCategory);

  if (isLoading) {
    return (
      <Flex justify='center' align='center'>
        <Spin size='large' />
      </Flex>
    );
  }

  if (!data) {
    return <AlertBasicServerError />;
  }

  const state = data.find((component) => component.key === AD_DETAILS_API_IDS.ATTRIBUTES.ETAT.MAIN_KEY);
  const statePrecision = data.find((component) => component.key === AD_DETAILS_API_IDS.ATTRIBUTES.ETAT_PRECISION);

  const stateItems = state?.choices?.filter((choice) => choice?.id !== AD_DETAILS_API_IDS.ATTRIBUTES.ETAT.VALUES.RECONDITIONNE.ID);

  const changeState = (value) => {
    dispatch({
      type: HANDLE_CHANGE,
      payload: {
        input_key: AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY,
        substate_key: AD_DETAILS_API_IDS.ATTRIBUTES.ETAT.MAIN_KEY,
        value: value,
      },
    });
  };

  return (
    <>
      <div className='form-item-header'>
        <p className='h2'>
          État
          <Asterisk />
        </p>
      </div>

      <div className='state-guide-link'>
        <ModalStateDescription />
      </div>

      <Radio.Group className='custom-check-group' value={stateValue} onChange={(e) => changeState(e.target.value)}>
        {stateItems?.map((choice) => (
          <Radio
            key={choice.id}
            value={choice.value}
            onClick={(e) => {
              if (e.target.value === stateValue) {
                changeState(null);
              }
            }}
          >
            <span className='inline-block capitalize-first-letter'>{choice.value}</span>
          </Radio>
        ))}
      </Radio.Group>

      <Flex vertical gap={8}>
        <Flex vertical gap={4}>
          <p className='body2'>{statePrecision.label}</p>
          <p className='subtext'>{statePrecision.helptext}</p>
        </Flex>
        <Flex vertical gap={8}>
          <Input
            defaultValue={statePrecisionValue}
            onBlur={(e) => {
              const value = e.target.value;
              dispatch({
                type: HANDLE_CHANGE,
                payload: {
                  input_key: AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY,
                  substate_key: AD_DETAILS_API_IDS.ATTRIBUTES.ETAT_PRECISION,
                  value: value,
                },
              });
            }}
          />
        </Flex>
      </Flex>
    </>
  );
}

export default connect((state) => {
  const { form_fields } = state.products;
  const categories = form_fields[AD_DETAILS_API_IDS.CATEGORIES.CATEGORIE];
  const stateValue = form_fields[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY]?.[AD_DETAILS_API_IDS.ATTRIBUTES.ETAT.MAIN_KEY];
  const statePrecisionValue = form_fields[AD_DETAILS_API_IDS.ATTRIBUTES.MAIN_KEY]?.[AD_DETAILS_API_IDS.ATTRIBUTES.ETAT_PRECISION];
  return {
    categories,
    stateValue,
    statePrecisionValue,
  };
})(State);
